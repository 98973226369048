<template>
  <div class="payment-box" v-show="!isMobile || (isMobile && token)">
    <div class="mobile-form-box mobile-address-box" @click="addressModal=true" v-if="transType=='delivery'&&isMobile" >
      <span class="label" v-if="nowAddressId && nowAddress.id" style="display: flex;flex-direction: column;">
        <span>{{nowAddress.doorNumber + ' ' + nowAddress.street + ', ' + nowAddress.city + ' '  + nowAddress.zipcode + ', ' + nowAddress.country}}</span>
        <span style="color: gray;">
          <span style="margin-right: 15px;" >{{nowAddress.fullName}}</span>
					<span>{{nowAddress.contactPhone}}</span>
        </span>
      </span>
      <span class="label" v-else >Select Home address</span>
      <span class="value" ></span>
      <i class="el-icon-arrow-right"></i>
    </div>

    <div class="mobile-form-box" @click="clickChooseTime">
      <span :class="['label', { 'empty-label': showEmptyBookTime }]" v-if="transType=='delivery'" >Delivery Time</span>
      <span :class="['label', { 'empty-label': showEmptyBookTime }]" v-else >Collection Time</span>
      <span class="value" v-if="dateLading">
        <i class="el-icon-loading" ></i>
      </span>
      <span class="value font-large" v-else>{{ formObj.bookTime }}</span>
      <template v-if="!isMobile">
        <el-cascader class="cover-cascader-left" popper-class="timer-pop" :disabled="dateLading"
          v-model="formObj.bookTime" :options="multiSelector" @change="handleChangeTime"></el-cascader>
        <el-cascader class="cover-cascader" popper-class="timer-pop" :disabled="dateLading"
          v-model="formObj.bookTime" :options="multiSelector" @change="handleChangeTime" ></el-cascader>
        </template>
        <i class="el-icon-arrow-right"></i>
    </div>

    <div class="mobile-form-box" @click="showPickTranstype=true">
      <span class="label" >Shipping method</span>
      <span class="value font-large" >{{ transMap[nowTransType] }}</span>
      <i class="el-icon-arrow-right"></i>
    </div>
    <header class="header-box">
      <i class="el-icon-s-goods"></i>
      <span>Your Cart</span>
    </header>
    <div class="item-box">
      <ul>
        <li v-for="item in cartList" :key="item.id" class="cart-item">
          <span class="add-btn" >
              {{ item.qty }}
              <i class="el-icon-close"></i>
          </span>
          <span class="name">
            {{ item.itemName }}
            <div class="food-desc" v-if="item.chooseItems">
              {{item.chooseItems}}
                <template v-for="(addon) in item.subItems" >
                  {{ ', ' + addon.subItemName }}
                </template>
            </div>
          </span>
          <span class="price" >{{ currencySign }} {{ item.price }}</span>
        </li>
      </ul>
      <div class="title-line" v-if="false">
        <span :class="['label', { 'empty-label': showEmptyBookTime }]" >Delivery Time</span>
        <span class="value" style="font-weight: bold;">{{ form.bookTime }}</span>
      </div>
      <div class="normal-line" >
        <div class="discount-box">
          <span class="label">Discount</span>
          <!-- <span class="value" >-￡{{ otherPriceInfo.discount }}</span> -->
        </div>
        <!-- 商家优惠  折扣 -->
        <div class="discount-desc" v-if="otherPriceInfo.isMerchantOff && otherPriceInfo.offType == 'discount'" >
          <span class="label">{{ otherPriceInfo.offPercent }}%OFF for orders above {{currencySign}}{{ otherPriceInfo.offLimit }}</span>
          <span class="value" >{{ otherPriceInfo.offAmount?'- '+currencySign+otherPriceInfo.offAmount.toFixed(2):'0.00' }}</span>
        </div>
        <!-- 赠送 -->
        <div class="discount-desc" v-if="otherPriceInfo.isMerchantOff && otherPriceInfo.offType == 'freeItem'" >
          <span class="label"></span>
          <span class="value" >FreeItem</span>
        </div>
        <!-- 优惠券码 -->
				<div class="discount-box"  v-if="otherPriceInfo.couponCodeDiscount">
          <span class="label">Coupon</span>
          <span class="value" style="color: #e74c3c">-{{currencySign}}{{ otherPriceInfo.couponCodeDiscount }}</span>
        </div>
      </div>
      <!-- Coupon -->
      <div class="title-line"  v-if="true">
        <div class="coupon-box" >
          <input type="text" placeholder="Coupon code" v-model="formObj.couponCode">
          <span class="apply-btn" v-if="loading">
            <i class="el-icon-loading"  ></i>
          </span>
          <span class="apply-btn" @click="applyCoupon" v-else>Apply Coupon</span>
        </div>
      </div>
      <div class="normal-line" >
        <div class="normal-box">
          <span class="label">Subtotal</span>
          <span class="value" >{{currencySign}}{{ form.subTotal }}</span>
        </div>
        <div class="normal-box" >
          <span class="label">Use Points</span>
          <span class="value" >
            <el-input-number type="digital" v-model="formObj.points" size="small" :max="otherPriceInfo.pointTop*1" :precision="2" :step="1" :min="0"></el-input-number>
          </span>
        </div>
        <div class="normal-box" v-if="cantTips">
          <span class="label">Add Tip</span>
          <span class="value" >
            <el-input-number type="digital" v-model="formObj.tip" size="small" :precision="2" :step="0.5" :min="0"></el-input-number>
          </span>
        </div>
        <!-- 订桌号 -->
        <div class="normal-box"  v-if="form.tableNumber">
          <span class="label">Table Number</span>
          <span class="value" >No. {{ form.tableNumber }}</span>
        </div>

        <div class="normal-box">
          <span class="label">Packing Fee</span>
          <span class="value" >{{ currencySign }} {{ form.packagingFee }}</span>
        </div>
        <div class="normal-box">
          <span class="label">Service Fee</span>
          <span class="value" >{{ currencySign }} {{ form.serviceFee }}</span>
        </div>
        <div class="normal-box" v-if="form.merchantDeliveryCharges">
          <span class="label">Delivery Fee</span>
          <span class="value" >{{ currencySign }} {{ form.merchantDeliveryCharges }}</span>
        </div>
      </div>
      <div class="title-line" >
        <span class="label" style="font-size: 22px;">Total</span>
        <span class="value" style="font-weight:bold;" >{{ currencySign }} {{ form.total }}</span>
      </div>

      <!-- 附加留言 -->
      <div class="note-title" >
        <i class="el-icon-chat-dot-round"></i>
        <span>Note / instruction</span>
      </div>
      <div class="input-box">
        <el-input type="textarea" placeholder="Add note..." @input="inputRemarks" rows="4" v-model="formObj.remarks" maxlength="300" show-word-limit>
        </el-input>
      </div>

      <div :class="['order-btn', { 'inactive-btn': (form.isOverDelivery || !clientId || form.subTotal*1 < merInfo[nowTransType+'MinimumOrder']*1)}, { 'nofixed': !fixOrderBtn }]" 
          @click="wantPay">
        <span style="flex:1;text-align:center;color:#fff;" v-if="form.subTotal*1 < merInfo[nowTransType+'MinimumOrder']*1" >
          Minimum Order {{ currencySign }} {{ merInfo[nowTransType+'MinimumOrder'] }}
        </span>
        <span style="flex:1;text-align:center;color:#fff;" v-else-if="form.isOverDelivery" >Out of area</span>
        <span style="flex:1;text-align:center;color:#0A191E;" v-else-if="clientId && form.total*1" >Proceed To Pay</span>
        <span style="flex:1;text-align:center;color:#fff;" v-else >{{clientId?'Laoding':'Login to continue'}}</span>
        <span class="price-box" >{{ currencySign }} {{ showTotalPrice }}</span>
      </div>
    </div>

    <!-- 移动端时间选择器 -->
    <!-- <el-drawer
      title="Shipping method"
      :visible.sync="showPickTranstype"
      direction="btt"
      size="40%"
      @close="()=>{showPickTranstype=false}">
      <div class="trans-drawer-box">
        <ul>
          <li :class="['trans-btn', { 'active': nowTransType==item.value }]" v-for="item in transTypeList" :key="item.value" 
            @click="chooseTransType(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </el-drawer> -->

    <!-- transtype弹窗 -->
    <el-dialog
        :visible.sync="showPickTranstype"
        :width="isMobile?'90%':'400px'"
        :close-on-click-modal="false"
        top="30vh"
        :before-close="handleClose"
        :show-close="false"
        :modal-append-to-body="false"
        custom-class="transtype-modal">
        <div slot="title" class="modal-title">
            <div class="gray-line" ></div>
            <div>Choose The Order Mode </div>
        </div>
        <div class="modal-content trans-modal" >
            <div class="trans-drawer-box">
                <ul>
                    <li :class="['trans-btn', { 'active': nowTransType==item.value }]" v-for="item in transTypeList" :key="item.value" 
                        @click="chooseTransType(item)">
                        <span class="name" > {{ item.name }} </span>
                        <i v-if="item.value == 'delivery'" class="iconfont icon-waimai"></i>
                        <i v-if="item.value == 'pickup'" class="el-icon-s-goods"></i>
                        <i v-if="item.value == 'dinein'" class="el-icon-dish"></i>
                    </li>
                </ul>
            </div>
            <div class="img-box" >
                <img v-if="nowTransType" :src="require(`@/assets/menu/modal_${nowTransType}.png`)" :alt="nowTransType">
                <div class="color-bg" ></div>
            </div>
        </div>
        <span slot="footer" class="modal-footer">
            <button style="background-color: #09ca6a;font-size:20px;cursor:pointer;" @click="showPickTranstype=false">
                <span>OK</span>
            </button>
        </span>
    </el-dialog>

    <!-- 添加地址 -->
    <el-drawer
      title=""
      :visible.sync="addressModal"
      direction="btt"
      size="50%"
      @close="()=>{addressModal=false}">
      <div slot="title" class="drawer-title" >Delivery Address</div>
      <div class="address-drawer-box">
        <div class="scroll-box" >
          <ul>
            <li v-for="(item) in addressList" :key="item.id" @click="chooseAddress(item)">
              <span :class="['radio', {'radio-active': item.id==nowAddress.id}]"></span>
              <i class="el-icon-location" ></i>
              <span class="address-name">{{item.doorNumber + ' '+ item.locationName + item.city + ' '  + item.zipcode + ', ' + item.country}}</span>
            </li>
          </ul>
          <div class="empty-box" v-if="!addressList.length" >
            <img src="@/assets/menu/empty_cart2.png" alt="empty">
          </div>
        </div>
        <div class="add-address-btn" @click="goAddAddress">
          <a>Add New Address</a>
        </div>
      </div>
    </el-drawer>

    <!-- 下单最终确认 -->
    <el-dialog
      title="Confirm information"
      :visible.sync="confirmModal"
      :width="isMobile?'90%':'30%'"
      @close="confirmModal=false">
      <div class="confirm-box">
        <div class="mobile-form-box" @click="clickChooseTime">
          <span class="label" v-if="transType=='delivery'" >Delivery Time</span>
          <span class="label" v-else >Collection Time</span>
          <span class="value" >{{ formObj.bookTime }}</span>
          <el-cascader class="cover-cascader" popper-class="timer-pop" v-if="!isMobile"
              v-model="formObj.bookTime" :options="multiSelector" @change="handleChangeTime"></el-cascader>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="tips">How would you like to pay?</div>
        <div class="payment-box-modal" >
          <div v-if="paymentLoading" class="payment-loading">
            <i class="el-icon-loading" ></i>
          </div>
          <ul v-else>
            <li v-for="(item,key) in payWayList" :key="key" @click="setPaymentType(item)">
              <span :class="['radio', {'radio-active': item.checked}]"></span>
              <span class="img-box">
                <img :src="getImg(imgs[item.paymentValue].src)" :style="{width:imgs[item.paymentValue].width, height: imgs[item.paymentValue].height}" alt="" />
              </span>
              <span class="label"> {{ item.paymentName }} </span>
            </li>
          </ul>
        </div>
        <div class="tips" style="margin-top: 10px;" >Note / instruction</div>
        <div class="remark-box" >
          <el-input type="textarea" placeholder="Add note..." @input="inputRemarks" rows="4" v-model="formObj.remarks" maxlength="300" show-word-limit>
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmModal = false">Cancel</el-button>
        <el-button type="primary" style="background-color: #888788;" v-if="form.isOverDelivery" >Out of area</el-button>
        <el-button type="primary" style="background-color: #aa0bc0;" v-else @click="payNow">Confirm Order</el-button>
      </span>
    </el-dialog>

    <!-- 时间选择器内容 -->
    <DateTimePicker v-model="showPicker" :timeOptions="multiSelector" @confirm="handleConfirmTime" ></DateTimePicker>

    <!-- 提示用户去app享受折扣 -->
    <el-dialog
      title=""
      :visible.sync="showGoAppGetCoupon"
      :width="isMobile?'90%':'460px'"
      @close="showGoAppGetCoupon=false">
      <div class="confirm-box-treat">
        <div class="tips">
          Congrats! You've unlocked a special reward! <br />
          Use this code on the All Eat App to get a <br />
          discount on your next <span style="color: red;margin: 0 10rpx;font-weight: 700">{{2}}</span> orders.
        </div>
        <div class="img-box">
          <img src="@/assets/baozang.gif" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGoAppGetCoupon = false">Cancel</el-button>
        <el-button type="primary" style="background-color: #aa0bc0;" @click="goApp">Go App</el-button>
      </span>
    </el-dialog>
    

  </div>
</template>

<script>

import { postGateway } from "@/request";
import pickerList from "@/utils/getPickTimeList";
import DateTimePicker from '@/components/CheckoutComps/DateTimePicker.vue';
import debounce from '@/utils/debounce';
import bus from '@/utils/bus';
export default {
  components: {
    DateTimePicker
  },
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    fixOrderBtn: {
      type: Boolean,
      default: true
    },
    cartList: {
      type: Array,
      default: () => []
    },
    outAddressModal: {
      type: Boolean,
      defalut: false
    },
    form: Object,
    merchantId: [String, Number],
    transType: {
      type: String,
      defalut: ''
    },
    otherPriceInfo: Object,
    nowAddressId: [String, Number],
    paymentType: String,
  },
  data() {
    return {
      clientId: localStorage.getItem("clientId"),
      formObj: {
        remarks: '',
        points: 0,
        couponCode: '',
        tip: 0,
        bookTime: 'As soon as possible', // 预定时间
        deliveryAsap: 1,   // 是否立即送出
				deliveryDateStr: '',   // 配送时间
      },
      dateLading: true,
      paymentLoading: true,
      loading: false,
      showPickTranstype: false,
      addressModal: false,
      confirmModal: false,
      addressList: [],
      list: ['card','cash','paypal'],
      payWayList: [],
      showEmptyBookTime: false,
      cantTipList: [1327, 846, 1189, 1338, 624, 2096 ],
      imgs: {
        'paypal_v2': {
          src: 'assets/icon/icon_paypal.png',
          width: '24px',
          height: '28px',
          name: 'Paypal',
          sort: 2,
        },
        'paypal_pro': {
          src: 'assets/icon/icon_card.png',
          width: '32px',
          height: '23px',
          name: 'Other Card Payments',
          sort: 5,
        },
        'cod': {
          src: 'assets/icon/icon_cash.png',
          width: '30px',
          height: '30px',
          name: 'Cash',
          sort: 3,
        },
        'stp': {
          src: 'assets/icon/icon_stripe.png',
          width: '30px',
          height: '30px',
          name: 'Card Payment(Stripe)',
          sort: 4,
        },
        'viva': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card',
          sort: 1,
        },
        'trustPay': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card Pay',
          sort: 0,
        },
        'guavaPay': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card Payments',
          sort: 0,
        },
      },
      transMap: {
        'delivery': 'Delivery',
        'pickup': 'Pick-up',
        'dinein': 'Dine-in'
      },
      nowTransType: '',
      nowAddress: {

      },
      transTypeList: [],
      merInfo: {
        merchantEnabledTip: 0
      },
      multiSelector: [
				{
					value: '',
					label: 'Today',
					children: [
            {
							value: 'asap',
							label: 'As soon as possible'
						}
					]
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
			],
      token: '',
      showPicker: false,
      usedCount: 0,
      showGoAppGetCoupon: false
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 950
    },
    nowToken() {
      return localStorage.getItem('token')
    },
    currencySign() {
      return this.merInfo.currencySign || ''
    },
    showTotalPrice() {
      return (this.form.total - ((this.formObj.points/100) || 0)).toFixed(2)
    },
    cantTips() {
      let can = true;
      let cantTipList = [ 1327, 846, 1189, 1338, 624, 2096 ];  // 小费拦截
			if(cantTipList.includes(this.merchantId*1)) {
				can = false;
			}
      return can && this.merInfo.merchantEnabledTip
    }
  },
  watch: {
    'nowToken': function(val) {
      if(val) {
        this.token = val;
      }
    },
    'merchantId': function() {
      this.getMerchantInfo();
    },
    'transType': function(val) {
      this.dateLading = true;
      this.nowTransType = val;
      if(val == 'pickup' && this.merchantId != 2468) {
        this.formObj.deliveryAsap = 0;
        this.formObj.bookTime = '';
      }
      this.getMerchantInfo();
    },
    'formObj.points': function() {
      this.$emit('applyCoupon', this.formObj)
    },
    'formObj.tip': function() {
      this.$emit('applyCoupon', this.formObj)
    }
  },
  mounted() {
    bus.$on('login',()=>{
      this.clientId = localStorage.getItem('clientId')
      this.token = localStorage.getItem('token')
      this.getAddressList();
    })
    let userInfo = localStorage.getItem('userInfo');
    if(userInfo && userInfo.clientId * 1) {
      this.clientId = userInfo.clientId;
      this.token = localStorage.getItem('token')
    }
    bus.$on('couponLoadDone',()=>{
      this.loading = false;
    })
    bus.$on('modifyAddress', ()=>{
      this.getAddressList(true);
    })
    setTimeout(() => {
      if(localStorage.getItem( `${this.merchantId}-FoodRemark`)) {
        this.formObj.remarks = localStorage.getItem(`${this.merchantId}-FoodRemark`)
      }
    }, 500);
    bus.$on('newIntoCheckout',()=>{
      setTimeout(() => {
        this.getMerchantInfo();
      }, 200);
    })
    this.token = localStorage.getItem('token')
    this.nowTransType = this.transType;
    this.getMerchantInfo();
  },
  methods: {
    goApp() {
      window.open('https://www.alleatapp.com/m');
    },
    handleConfirmTime(val) {
      // this.formObj.bookTime = val
      this.handleChangeTime(val)
    },
    clickChooseTime() {
      this.showEmptyBookTime = false;

      if(this.isMobile) {
        this.showPicker = true;
      }
    },
    inputRemarks() {
      debounce(()=>{
        localStorage.setItem(`${this.merchantId}-FoodRemark`,this.formObj.remarks)
      }, 1000)
    },
    handleClose() {
      this.showModal = false;
    },
    getImg(path) {
      return path?require('@/' + path):''
    },
    // 获取商家支付方式
    queryMerchantPaymentList() {
      this.paymentLoading = true;
      postGateway({
        url: '/sys/paymentType/queryMerchantPayment',
        method: 'GET',
        data: {
          merchantId: this.merchantId
        }
      }).then(res=>{
        this.payWayList = [];
        res.data.forEach((ele)=>{
          if(ele.paymentValue != 'stp') {
            this.payWayList.push({
							checked: ele.paymentValue == 'paypal_v2' ?true:false,
							...ele,
              paymentName: ((ele.paymentValue == 'paypal_v2' && this.currencySign != '￡')?'Paypal': ele.paymentName)
						})
						if(ele.paymentValue == 'paypal_v2') {
              console.log(this.currencySign,'this.currencySign')
							this.$emit('changePayment', ele.paymentValue)
						}
          }
				})
        this.payWayList = this.payWayList.sort((a,b)=>{
          return this.imgs[a.paymentValue].sort - this.imgs[b.paymentValue].sort
        })
        this.paymentLoading = false;
      }).catch(err=>{
        this.paymentLoading = false;
        console.log(err,'报错')
      })
    },
    setPaymentType(item) {
      this.payWayList.forEach(ele=>{
        ele.checked = false;
      })
      item.checked = true;
      this.$emit('changePayment', item.paymentValue)
    },
    goAddAddress() {
      this.addressModal = false;
      if(this.isMobile) {
        this.$router.push({
        path: '/editAddress'
      })
        return
      }
      this.$router.push({
        path: '/mySecond?name=Delivery&index=1'
      })
    },
    chooseAddress(item) {
      this.nowAddress = item;
      this.$emit('chooseAddress', item)
      this.addressModal = false;
      // console.log(item,'address')
    },
    addAddress() {
      // this.$emit('addAddress')
    },
    getAddressList(isModify=false) { // 获取用户保存的地址
      this.clientId = localStorage.getItem('clientId');
      if(!this.token) {
        return
      }
      postGateway({
        method: 'GET',
        url: '/customer/address/queryList',
        data: {
          clientId: this.clientId
        }
      }).then(res=>{
        this.addressList = res.data
        this.addressList.forEach(ele=>{
          if(ele.asDefault){
            this.chooseAddress(ele)
          }
        })
        // 如果新增了这里显示它弹窗
        console.log(isModify,'isModify')
        // if(isModify) {
        //   this.addressModal = true;
        // }
      }).catch(err=>{
        console.log(err, '地址获取报错')
      })
    },
    handleChangeTime(val) {
      let [date, time] = val;
      let dateStr;
      if(date) {
          let [mon, day, year] = date.split('/');
          dateStr = `${year}/${mon}/${day}`
      }
      if(time == 'asap') {  // 立即送出
				this.formObj.deliveryAsap = 1;
				this.formObj.deliveryDateStr = '';
			} else {
				this.formObj.deliveryAsap = 0;
				this.formObj.deliveryDateStr = dateStr + ' ' + time;
			}
			this.formObj.bookTime = this.formObj.deliveryAsap == 1? 'As soon as possible':this.formObj.deliveryDateStr.replace(/\//g,'-');  // 表单回显
      console.log(this.formObj, 'form', time)
      if(this.formObj.deliveryAsap != 1 && !time) {
        this.formObj.bookTime = ''
        return
      }
      this.$emit('changeBookTime', this.formObj)
    },
    timeToNum(str,judge=0) {
			let result = str.split(':').join('') * 1;
			let orTime = this.merInfo.orderTime * 1;
			
			// 跨天营业时间不需要计算orderTime
			let across = false;
			if(result === 0 && [1,2].includes(judge)) {
				across = true
			}
			
			// 需要处理 开店后时间
			if(judge && str) {
				let shi = str.split(':')[0]*1;
				let fen = str.split(':')[1]*1 + (across?0:orTime);
				shi = fen >= 60 ? shi+1 : shi;
				fen = (fen % 60)*1>=10?(fen % 60):(fen % 60) + '0';
				result = (shi +''+ fen) *1;
			}
			return result
		},
        // 获取明天之后的工作时间 并且赋值给时间选择器
		getTimeFlag(timeList,num) {
			let map = new Map();
			// 营业时间 一周的完整营业时间 选择
			timeList.forEach(ele1=>{
				let arr = [];
				pickerList.normal.forEach(ele=>{
					if((ele1.startTime1 && this.timeToNum(ele) >= this.timeToNum(ele1.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(ele1.endTime1))
					||  (ele1.startTime2 && this.timeToNum(ele) >= this.timeToNum(ele1.startTime2,(ele1.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(ele1.endTime2))) {
						arr.push({
							label: ele,
							value: ele
						})
					}
				})
				map.set(ele1.weekday,arr)
			})
			
			// map 是个完整的每周工作时间
			// 只需要计算 后天开始 - 结束日期-2  的时间

			// 今天周几
			let todayDay = new Date().getDay()==0?7:new Date().getDay();
			for (let i=2; i<num+1; i++) {
				this.multiSelector.push({
					value: this.$moment().add(i, 'days').format('L'),
					label: this.$moment().add(i, 'days').format('L').replace(/\//g,'-') ,
					children: map.get(((todayDay + i) % 7 == 0?7:(todayDay + i) % 7))  // 把周几传进来就可以获取工作时间了
				})
			}	
							
		},
    initFormObj() {
      let { bookTime, deliveryAsap, deliveryDateStr } = this.form;
      this.formObj.bookTime = deliveryAsap==1 ? deliveryDateStr : bookTime;
      this.formObj.deliveryAsap = deliveryAsap;
      this.formObj.deliveryDateStr = deliveryDateStr;
      if(this.nowTransType == 'pickup' && this.formObj.deliveryAsap == 1 && this.merchantId != 2468) {
        this.formObj.deliveryAsap = 0;
        this.formObj.bookTime = '';
      }
    },
    getDefaultTime() {
      this.multiSelector = [
				{
					value: '',
					label: 'Today',
					children: [
            {
							value: (this.nowTransType == 'pickup' && this.merchantId != 2468)?'':'asap',
							label:  (this.nowTransType == 'pickup' && this.merchantId != 2468)?'':'As soon as possible'
						}
					]
				},
				{
					value: '',
					label: 'Tomorrow',
					children: []
				},
			]
    },
    // 初始化 选择时间 List  只用计算 dinein 的
		initPickTime() {
      
      this.dateLading = true;
        return postGateway({
            url: '/merchant/merchantOperateTime/getList',
            method: 'GET',
            data: {
                merchantId: this.merchantId
            }
        })
        .then(({data})=>{
          this.getDefaultTime();
				let res = data;
				// 获取每周的开店时间
				let timeList = this.transType?res[this.transType]:(res&&res.dinein)?res.dinein:[];
				
				// 计算周几
				let timeFlag1 = new Date().getDay()==0?7:new Date().getDay();  //  0-6
				let timeFlag2 = ((timeFlag1 + 1) % 7 == 0?7:(timeFlag1 + 1) % 7);
				let timeFlag3 = ((timeFlag1 + 2) % 7 == 0?7:(timeFlag1 + 2) % 7);
				
				console.log(res.dinein,'营业时间',timeFlag1,timeFlag2,timeFlag3);
				// 关店时间限制
				let limit = {
					t1: null,
					t2: null,
				};

				timeList.forEach(ele=>{
					if(ele.weekday == timeFlag1) {
						limit.t1 = ele;
					}
					if(ele.weekday == timeFlag2) {
						limit.t2 = ele;
					}
				}) 	
				
				let limitTime = this.$moment(new Date().valueOf() + this.merInfo.orderTime*60000).format('HH:mm').split(':');
				
				// 目前仅处理3天的 下单时间
				this.multiSelector[0].value = this.$moment().format('L')
				this.multiSelector[1].value = this.$moment().add(1, 'days').format('L') // 明天					
				
                // TODO 待优化 时间前后颠倒会有问题
				pickerList.normal.forEach(ele=>{
					// 今天只能选择 营业时间 45分钟以后
					if(  (ele.split(':')[0]*1 > limitTime[0]*1 || (ele.split(':')[0] == limitTime[0] && ele.split(':')[1]*1 > limitTime[1]*1)) && limitTime[0] !== '00') {
						
						if((limit.t1.startTime1 && this.timeToNum(ele) >= this.timeToNum(limit.t1.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(limit.t1.endTime1))
                            || (limit.t1.startTime2 && this.timeToNum(ele) >= this.timeToNum(limit.t1.startTime2,(limit.t1.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(limit.t1.endTime2))) {
							this.multiSelector[0].children.push({
								label: ele,
								value: ele,
							})
						}
					}
					
					// 明天
					if((limit.t2.startTime1 && this.timeToNum(ele) >= this.timeToNum(limit.t2.startTime1,1) && this.timeToNum(ele) <= this.timeToNum(limit.t2.endTime1))
					||  (limit.t2.startTime2 && this.timeToNum(ele) >= this.timeToNum(limit.t2.startTime2,2,(limit.t2.startTime1?0:2)) && this.timeToNum(ele) <= this.timeToNum(limit.t2.endTime2))) {
						this.multiSelector[1].children.push({
							label: ele,
							value: ele
						})
					}
					
				})
				
				// 处理后天之后的时间
				this.getTimeFlag(timeList,this.merInfo.timesDay*1)

        let nowTime = this.$moment(new Date().valueOf()).format('HH:mm');

        if((limit.t1.startTime1 && this.timeToNum(nowTime) < this.timeToNum(limit.t1.startTime1) || ( limit.t1.endTime1 && this.timeToNum(nowTime) > this.timeToNum(limit.t1.endTime1)
        && limit.t1.startTime2 && this.timeToNum(nowTime) < this.timeToNum(limit.t1.startTime2)) || (limit.t1.endTime2 && this.timeToNum(nowTime) > this.timeToNum(limit.t1.endTime2))) 
        || (this.transType === 'pickup' && this.merchantId != 2468)) {
					this.multiSelector[0].children[0] = {
						label: '',
						value: ''
					}
					
					if(this.multiSelector[0].children.length > 1) { // 有时间 没有asap 的情况 第一行去掉
						this.multiSelector[0].children.shift();
					}
					
					this.$nextTick(()=>{
            this.formObj.bookTime = '' // 没有 asap了
          })
				}
				
        if(this.multiSelector[0].children.length == 1 && this.merchantId != 2468 ) {  // 只有一个 说明今天没得送了
					this.multiSelector[0].children = [
						{
							label: 'Shop Closed',
							value: ''
						}
					];
					this.formObj.bookTime = ''
				}
        
				if(this.merInfo.timesDay*1 == 0) {  // 只能预定当天的
					this.multiSelector.pop()
				}
				
        if(this.merchantId == 2195 ) {
          this.multiSelector.forEach((ele, ind)=>{
            let arr = [];
            ele.children.forEach((e, i)=>{
              if(ind == 0 && ((i-1)%3 == 0 || i==0)) {
                arr.push(e)
              } else if(ind != 0 && i%3 == 0) {
                arr.push(e)
              }
            })
            ele.children = arr;
          })
        }

        // 如果是周六 并且是 delivery 这家商家只要 asap
        if(this.transType == 'delivery' && new Date().getDay() == 6 && [2028].includes(this.merchantId*1)){ 
            this.multiSelector[0].children = [
                {
                  value: 'asap',
                  label: 'As soon as possible'
                }
            ];
        }

        // 这家商家 只要 asap
        if([2422].includes(this.merchantId*1)){ 
            this.multiSelector[0].children = [
                {
                  value: 'asap',
                  label: 'As soon as possible'
                }
            ];
            this.multiSelector = [this.multiSelector[0]];
        }

        this.dateLading = false;
			})
      .catch(()=>{
        this.dateLading = false;
      })
			
		},
    getMerchantInfo() {
        let param = {
            merchantId: this.merchantId,
            type: this.transType == 'delivery'?1:this.transType == 'pickup'?2:this.transType == 'dinein'?3: 0,
        }
        if(this.nowTransType == 'delivery') {
          this.getAddressList();
        }
        postGateway({
            url: '/customerApp/merchantDetail/getMerchantDetail',
            method: 'GET',
            data: param
        })
        .then(res=>{
            this.merInfo = res.data;
            this.queryMerchantPaymentList();
            this.filterTranstype();
            this.initPickTime().then(()=>{
              this.initFormObj();
            })
        })
        .catch(()=>{})
    },
    filterTranstype() {

      this.transTypeList = [];
      let { delivery, pickup, dinein } = this.merInfo;
      if(delivery) {
        this.transTypeList.push({
          name: 'Delivery',
          value: 'delivery',
          active: false
        })
      }
      if(pickup) {
        this.transTypeList.push({
          name: 'Pick-up',
          value: 'pickup',
          active: false
        })
      }
      if(dinein) {
        this.transTypeList.push({
          name: 'Dine-in',
          value: 'dinein',
          active: false
        })
      }
    },
    chooseTransType(item) {
      this.nowTransType = item.value;
      this.$emit('changeTransType', item.value);
      // this.showPickTranstype = false;
    },
    // 获取完整折扣内容
		getDiscountStr (disc) {
			let str = '';
			if(str) {
				// 中文
				if(disc.type == 'discount') {
					str = `满 ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount} 享 ${disc.percent}% 折扣`;
				}
				if(disc.type == 'freeItem') {
					str = `满 ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount} 赠 ${disc.freeItemNames}`
				}
			} else {
				// 英文
				if(disc.type == 'discount') {
					str = `${disc.percent} % OFF for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
				}
				if(disc.type == 'freeItem') {
					str = `${disc.freeItemNames} for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
				}
			}
			return str;
		},
    // 添加优惠码
    applyCoupon() {
      this.loading = true;
      postGateway({
        url: "/sys/merchant/couponCode/checkCouponCode",
        method: "GET",
        data: {
          clientId: this.clientId,
					code: this.formObj.couponCode,
					requestFrom: 'web'
        }
      }).then(res=>{
        this.loading = false;
        console.log(res,'填写 - couponcode');
        let { status, countLimit, useCount } = res.data;
        this.usedCount = 0;
        if(status == 'expire' && !['ALLEATIE','suezp'].includes(this.formObj.couponCode) ) {
          this.$message.warning('Coupon code has expired or is invalid.');
          this.formObj.couponCode = '';
          return
        }
        if(status == 'active') {
          this.usedCount = countLimit - useCount;
          this.$emit('applyCoupon', this.formObj); // 如果有效才请求购物车信息
          setTimeout(() => {
            this.$message.success('Apply success.');
          }, 500);
        }
        if(this.usedCount == 1 && ['ALLEATIE','suezp'].includes(this.formObj.couponCode) && countLimit != 1) { // 这个码web只能用一次
          this.showGoAppGetCoupon = true;
          this.formObj.couponCode = '';
          this.$emit('applyCoupon', this.formObj);
        }
      })
      .catch(err=>{
        this.loading = false;
        this.$message.warning(err.msg)
      })
    },
    scrollToTarget() {
      let dom = document.querySelector('.payment-box');
      let target = dom.offsetTop-300;
      if(window.innerWidth < 950) {
        target = dom.offsetTop-100;
      }
      document.body.scrollTop = document.documentElement.scrollTop  = target;
    },
    // 下单前确认
    wantPay() {
      if(this.form.subTotal*1 < this.merInfo[this.nowTransType+'MinimumOrder']*1) {
        return 
      }
      if(this.form.isOverDelivery) {  //地址太远了
        this.$message.warning('Overy Delivery')
        return
      }
      if(!this.payWayList.length) {
        this.queryMerchantPaymentList();
      }
      /* eslint-disable */

      if(this.outAddressModal) {
        this.$message.warning('Please save the form.')
        return
      }

      if((!this.nowAddressId && this.transType == 'delivery')) {
        this.$message.info('Please Choose Address.')
        bus.$emit('chooseAddress', true)
        setTimeout(() => {
          if(this.isMobile) {
            this.addressModal = true;
          } else {
            this.scrollToTarget();
          }
        }, 500);
        return
      }

      // 时间选择拦截
      if( !this.formObj.bookTime && this.formObj.deliveryAsap != 1) {
        if(this.transType == 'delivery') {
          // this.$message.info('Please choose delivery time.')
        } else {
          this.$message.info('Please choose collection time.')
          this.clickChooseTime();
        }
        this.showEmptyBookTime = true;
        this.scrollToTarget();
        setTimeout(()=>{
          this.showEmptyBookTime = false;
        }, 3000)
				return
      }

      if(!this.clientId || !this.form.total*1) {
        return
      }

      console.log(this.formObj.bookTime, this.formObj.deliveryAsap, 'this.formObj.deliveryAsap')

      this.payWayList.forEach(ele=>{
        ele.checked = ele.paymentValue == this.paymentType
      })
      this.confirmModal = true;
    },
    // 下单并支付
    payNow() {
      console.log('去下单了啊')

      this.$emit('paynow', this.formObj)
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.payment-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  .header-box {
    background: $mainColor;
    width: 100%;
    height: 58px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.6);
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0 15px;
    .el-icon-s-goods {
      margin-right: 6px;
      font-size: 24px;
    }
  }

  .mobile-form-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0 10px;
    border-radius: 5px;
    margin-bottom: 12px;
    position: relative;
    .label {
      // font-weight: bold;
      color: #000;
      font-size: 12px;
      margin-right: 10px;
      word-break: break-word;
    }
    .value {
      cursor: pointer;
      word-break: break-word;
      white-space: nowrap;
    }
    .empty-label {
      font-size: 17px;
      color: rgb(222, 9, 9);
      text-decoration: underline;
      animation: jump 0.6s ease-in-out infinite alternate forwards;
    }
    @keyframes jump {
        from { 
          transform: scale(1.1);
        }
        to { 
          transform: scale(0.9);
        }
    }
    .value {
      font-weight: normal;
      flex: 1;
      text-align: right;
      font-size: 15px;
      margin-right: 10px;
      color: #000;
      font-weight: bold;
    }
    .font-large {
      font-size: 18px;
      white-space: nowrap;
    }
    .cover-cascader {
      position: absolute;
      right: 0;
      opacity: 0;
    }
    .cover-cascader-left {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }
  .mobile-address-box {
    height: unset;
    padding: 14px 10px;
  }

  .item-box {
    padding: 10px 10px;
    width: 94%;
    margin-top: 10px;
    min-height: 150px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);

    .cart-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 24px;
      .name {
          font-weight: 600;
          flex: 1;
          white-space: pre-wrap;
          word-break: normal;
          color: #000;
          padding-bottom: 4px;

          .food-desc {
            font-weight: 400;
            font-size: 10px;
            color: gray;
          }
      }
      .price {
          padding: 0 10px;
          font-weight: 600;
          text-align: left;
          min-width: 80px;
          display: inline-flex;
          justify-content: flex-end;
          color: #000;
      }
      .add-btn {
          // width: 70px;
          height: 26px;
          // border: 1px solid #AA0BC0;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          border-radius: 20px;
          font-size: 15px;
          color: #000;
          font-weight: 600;
          padding: 0 8px 0 0;
          margin-right: 5px;
          cursor: pointer;
          transition: all ease-in .2s;
          i {
              color: #AA0BC0;
              font-weight: 700;
              font-size: 12px;
          }
          &:hover {
              background-color: #AA0BC0;
              border: 2px solid #fff;
              color: #fff;
              i {
                  color: #fff;
              }
          }
      }
      .el-icon-delete  {
          margin-left: 4px;
          font-size: 20px;
          cursor: pointer;
      }
    }

    .title-line {
      border-top: 1px solid #8F9698;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: bold;
        color: #000;
        font-size: 18px;
        margin-right: 24px;
      }
      .value {
        font-weight: normal;
        font-size: 18px;
        color: #000;
      }

      .coupon-box {
        width: 100%;
        margin-top: 10px;
        height: 48px;
        border-radius: 24px;
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
        input {
          flex: 1;
          height: 40px;
          color: #000;
          padding-left: 24px;
          overflow: hidden;
          border-radius: 20px;
          font-size: 18px;
          font-weight: bold;
        }
        .apply-btn {
          height: 48px;
          width: 150px;
          border-radius: 24px;
          padding: 0 20px;
          font-size: 16px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #0A191E;
          white-space: nowrap;
          font-weight: 600;
          color: #C2F93A;
          transition: all ease-in-out 0.2s;
          cursor: pointer;
          &:hover {
            background-color: #222525;
          }
        }
      }

    }

    .normal-line {
      border-top: 1px solid #8F9698;
      padding: 12px 24px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
      }
      .discount-box {
        font-size: 17px;
        .label {
          font-weight: bold;
        }
      }
      .normal-box {
        margin-bottom: 12px;
        font-size: 15px;
        .label {
          font-weight: bold;
        }
        .value {
          .add-btn {
            width: 70px;
            height: 26px;
            border: 2px solid #AA0BC0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            font-size: 15px;
            color: #000;
            font-weight: 600;
            padding: 0 8px;
            cursor: pointer;
            transition: all ease-in .2s;
            position: relative;
            i {
                color: #AA0BC0;
                font-weight: 700;
                font-size: 12px;
            }
            &:hover {
                background-color: #AA0BC0;
                border: 2px solid #fff;
                color: #fff;
                i {
                    color: #fff;
                }
            }
          }
        }
      }
      .discount-desc {
        color: #818080;
        padding-top: 4px;
        .value {
          color: #aa0bc0;
          font-weight: bold;
        }
      }
    }
    
    .note-title {
      border-top: 1px solid #8F9698;
      padding: 12px 24px;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      .el-icon-chat-dot-round {
        color: #000;
        font-size: 22px;
        margin-right: 12px;
      }
    }
    .input-box {
      margin: 0px 20px 12px;
      border-radius: 8px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      :deep(.el-textarea__inner) {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        font-family: "Source Sans Pro", sans-serif;
        padding-top: 12px;
      }
    }

    .order-btn {
        margin: 20px 20px 10px;
        height: 50px;
        display: flex;
        align-items: center;
        background-color: #07b75f;
        color: #fff;
        font-size: 20px;
        font-weight: 800;
        border-radius: 25px;
        padding-left: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: all ease-in-out .2s;
        &:hover {
          background-color: #09a054;
        }
        .price-box {
          height: 50px;
          border-radius: 25px;
          padding: 0 20px;
          min-width: 100px;
          background-color: #0A191E;
          white-space: nowrap;
          font-weight: 600;
          color: #C2F93A;
          display: flex;
          align-items: center;
        }
    }
    .inactive-btn {
      background-color: #cccccc;
      &:hover {
        background-color: #cccccc;
      }
    }

  }

  .transtype-modal {
    .modal-title {
        text-align: center;
        font-weight: 600;
        color: #000;
        font-size: 24px;
        text-align: center;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -15px;
        .gray-line {
            height: 4px;
            width: 90px;
            background: #d9d9d9;
            margin-bottom: 6px;
        }
    }
    :deep(.el-dialog__body) {
        padding-right: 0;
        padding-bottom: 0;
    }
    
    .modal-footer  {
        display: flex;
        justify-content: center;
        margin-top: -15px;
        button {
            height: 40px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: #fff;
            border-radius: 6px;
        }
    }
    .trans-modal {
        display: flex;
    }
    .modal-content {
        max-height: 60vh;
        overflow: auto;
        margin-right: 4px;
        &::-webkit-scrollbar {
            width: 6px;
        }
        .price-title {
            color: #09CA6A;
            font-size: 16px;
            text-align: left;
            margin-bottom: 15px;
        }
        .dash-row {
            margin: 15px 0;
            border-top: 1px dashed #cacaca
        }
        .addon-title {
            color: #aa0bc0;
            margin-bottom: 15px;
            margin-top: 10px;
        }
        .price-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            padding: 6px 10px;
            border-radius: 4px;
            border: 2px solid #f4f3f3;
            margin-bottom: 10px;
            color: #000;
            cursor: pointer;
        }
        .price-item-active {
            color: #aa0bc0;
            border: 2px solid #aa0bc0;
            background-color: #f9eafc;
        }
        .addon-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .price-item-addon {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                padding: 6px 10px;
                border-radius: 4px;
                border: 2px solid #f4f3f3;
                margin-bottom: 10px;
                color: #000;
                cursor: pointer;
                margin-right: 10px;
            }
            .price-item-active {
                color: #aa0bc0;
                border: 2px solid #aa0bc0;
                background-color: #f9eafc;
            }
        }
        .trans-drawer-box {
            padding: 0 20px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            ul {
                .trans-btn {
                    height: 40px;
                    color: #111;
                    font-size: 18px;
                    margin: 0 auto 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    border-radius: 2px;
                    white-space: nowrap;
                    padding: 0 16px;
                    cursor: pointer;
                    background: #d9d9d9;
                    .name {
                        margin-right: 10px;
                        font-size: 18px;
                        white-space: nowrap;
                        flex: 1;
                    }
                    .name-icon {
                        height: 20px;
                        width: 20px;
                    }
                    .desc {
                        font-size: 12px;
                        font-weight: 400;
                        color: #7a7a7a;
                    }
                }
                .active {
                    font-weight: bold;
                    color: #fff;
                    position: relative;
                    background: #09CA6A;
                }
            }
        }
        .img-box {
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .color-bg {
                display: none;
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translateX(-50%) translateY(-50%);
                // height: 140px;
                // width: 140px;
                // border-radius: 70px;
                // background: #d7c3ff;
            }
            img {
                width: 100%;
            }
        }
    }
  }

  // .trans-drawer-box {
  //   padding: 0 20px;
  //   cursor: pointer;
  //   ul {
  //     .trans-btn {
  //       height: 40px;
  //       width: 400px;
  //       margin: 0 auto 12px;
  //       border: 1px solid rgb(138, 137, 137);
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-weight: bold;
  //       border-radius: 5px;
  //       cursor: pointer;
  //     }
  //     .active {
  //       font-weight: bold;
  //       color: #fff;
  //       background-color: #07b75f;
  //     }
  //   }
  // }

  .drawer-title {
    font-size: 20px;
    font-weight: 600;
    color: #111;
    text-align: center;
  }
  .address-drawer-box {
    padding: 10px 20px 20px;
    min-height: 60px;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .loading-box {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-icon-loading {
        font-size: 20px;
      }
    }

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      min-height: 48px;
      padding: 8px 0;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      &:nth-last-child(1) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
    .address-name {
      flex: 1;
    }
    .el-icon-location {
      margin-right: 8px;
      color: #000;
      font-size: 15px;
    }
    .radio {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      margin-right: 10px;
    }
    .radio-active {
      &::after {
        content: '';
        position: absolute;
        z-index: 5;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $greenColor;
      }
    }
    .add-address-btn {
      width: 70%;
      margin: 10px auto 0;
      cursor: pointer;
      height: 45px;
      border-radius: 23px;
      background-color: $greenColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      color: #000;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      transition: all .2s ease-in-out;
      &:hover {
        background-color: #21bd6f;
      }
    }
    .scroll-box {
      overflow-y: auto;
      flex: 1;
      .empty-box {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
        }
      }
    }
    .add-address-btn {
      height: 40px;
      border: 1px solid black;
    }
  }

  // 支付选择
  .confirm-box {
    .tips {
      padding-bottom: 10px;
      padding-top: 10px;
      font-weight: bold;
      color: #000;
    }
  }
  .confirm-box-treat {
    .tips {
      padding-bottom: 10px;
      padding-top: 10px;
      font-weight: 500;
      line-height: 24px;
      color: #000;
      text-align: center;
    }
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 240px;
        width: 240px;
      }
    }
  }
  .remark-box {
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
    border-radius: 4px;
  }
  .payment-box-modal {
    padding: 10px 10px;
    // width: 86%;
    // min-height: 150px;
    word-break: normal;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);

    .loading-box, .payment-loading {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-icon-loading {
        font-size: 20px;
        margin-top: 40px;
      }
      .el-icon-refresh-right {
        font-size: 26px;
        cursor: pointer;
        color: #aa0bc0;
        font-weight: bold;
      }
    }

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      min-height: 48px;
      display: flex;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
      font-weight: 600;
      .label {
        font-weight: 600;
        color: #000;
        font-size: 20px;
      }
    }
    .img-box {
      // display: inline-block;
      height: 40px;
      width: 40px;
      margin-right: 8px;
      display: inline-flex;
      align-items: center;
    }
    .radio {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      margin-right: 15px;
      .el-icon-location {
        margin-right: 4px;
        color: #000;
      }
    }
    .radio-active {
      &::after {
        content: '';
        position: absolute;
        z-index: 5;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $greenColor;
      }
    }
  }

}

// 移动端布局
@media screen and (max-width: 950px) {
  .payment-box {
    .header-box {
      // display: none;
      height: 40px;
      // margin-top: -20px;
      span {
        font-size: 14px;
      }
    }
    .trans-drawer-box {
      padding: 0 20px;
      ul {
        .trans-btn {
          width: unset;
          cursor: pointer;
        }
      }
    }
    .item-box {
      padding: 10px 0;
      width: 100%;
      margin-top: 0;
      ul {
        .cart-item {
          padding: 0 12px;
          margin-top: 7px;
        }
      }
      .title-line {
        padding: 8px 12px;
        .label {
          font-size: 14px;
        }
        .value {
          font-size: 14px;
        }
        .coupon-box {
          margin-top: 0;
          height: 42px;
          input {
            font-size: 15px;
          }

        }
      }
      .normal-line {
        padding: 8px 12px;
        .label {
          font-size: 14px;
        }
        .value {
          font-size: 14px;
        }
      }
      .note-title {
        font-size: 14px;
        .el-icon-chat-dot-round {
          font-size: 18px;
        }
      }
      .order-btn {
        z-index: 1000;
        position: fixed;
        width: 96vw;
        margin: 0 2vw;
        bottom: 5px;
        left: 0;
      }
      .nofixed  {
        position: relative;
        width: unset;
      }
    }
  }
}

</style>
